import React from "react"
import PropTypes from "prop-types"

import "./layout.css"
import Bar from "./bar"

const Layout = ({ children }) => {

  return (
    <>
      <Bar/>
      <div
        style={{
          margin: `0 auto`,
          marginBottom: '0px',
          width: 720,
          padding: `0px 1.45rem`,
          paddingTop: 0,
          textAlign: 'center',
          fontSize: '20px',
          display: "flex",
          justifyContent: "center",
        }}
      >
        <main>{children}</main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
