import React, { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import "./layout.css"

function Dropdown({title, items}) {
    const [open, setOpen] = useState(false);
    const [selection, setSelection] = useState([]);
    const toggle = () => setOpen(!open);

    return (
        <div
        onMouseEnter={() => toggle(!open)}
        onMouseLeave={() => toggle(!open)}
        >
            <Link to='/projects' 
            activeStyle={{ backgroundColor: '#0b4474' }}
            className='header-link'>{title}</Link>

         <div style= {{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
            {open && (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'fixed',
                    backgroundColor: '#303030',
                }}>
                    {items.map(item => (
                        <Link to={'/projects#'+item.value} key={item.id} style={{
                            textDecoration: "none",
                            color: 'white',
                            fontSize: '18px'
                        }}>
                            <div className='dd-menu'>{item.value}</div>
                        </Link>
                    ))}
                </div>
            )}
            </div>
        </div>
    )
}

export default Dropdown