import React from "react"
import { Link } from "gatsby"

import "./layout.css"
import logo from "../images/logo.png"
import github from "../images/github.png"
import linkedin from "../images/linkedin.png"
import Nav from "./nav"
import Dropdown from "./dropdown"

const projectList = [
  {
    id: 1,
    value: 'Snake'
  },
  {
    id: 2,
    value: 'Pong'
  },
  {
    id: 3,
    value: 'Platformer'
  },
  {
    id: 4,
    value: 'Basketball DB'
  },
  {
    id: 5,
    value: 'Destiny Weapon Checklist'
  }
]

const Bar = () => {

    return (
      <header id="header" 
        style={{
          display: 'flex',
          position: 'fixed',
          width: '100%',
          zIndex: '1',
          paddingBottom: '3rem',
          fontSize: '20px'
        }}
      >
        <div
        style={{
          flex: '1',
          display: 'flex',
          justifyContent: 'flex-end',
          backgroundColor: '#000000',
        }}>
          <Link to='/' style={{
            display: 'flex',
            alignItems: 'center'
          }}>
          <img src={logo} alt="temp logo" width="50rem"></img>
          </Link>
        </div>

        <div 
          style={{
            flex: '2',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            backgroundColor: '#000000',
          }}>
          <Nav name='Resume' path='/resume'/>
          <Nav name='About Me' path='/about-me'/>
          <Dropdown title='Projects' items={projectList}/>
        </div>

        <div
        style={{
          flex: '1',
          backgroundColor: '#000000',
          display: 'flex',
          alignContent: 'center',
          gap: '15px'
        }}>
          <a href="https://github.com/griffin104" target="_blank" style={{
            display: 'flex',
            alignItems: 'center',
          }}>
            <img src={github} alt="GitHub Icon" style={{
              maxHeight: '40px',
            }}></img>
          </a>
          <a href="https://www.linkedin.com/in/griffinbrlw/" target="_blank" style={{
            display: 'flex',
            alignItems: 'center',
          }}>
            <img src={linkedin} alt="LinkedIn Icon" style={{
              maxHeight: '40px',
            }}></img>
          </a>
        </div>
      </header>
    )
}

export default Bar