import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import "./layout.css"


const Nav = ({ name, path }) => {
    return (
    <Link to={path} 
    activeStyle={{ backgroundColor: '#0b4474' }}
    className="header-link">
        {name}
    </Link>
    )
}

Nav.propTypes = {
    name: PropTypes.string,
    path: PropTypes.string,
}

Nav.defaultProps = {
    name: 'link',
    path: '/404'
}

export default Nav